<template>
    <svg ref="editor" class="canvas"
        :class="[multi ? 'multiselect' : '']"
        :viewBox="viewBox"
        preserveAspectRatio="xMinYMin meet"
        v-on:contextmenu.prevent="onContextMenu"
        v-on:wheel="onScroll"
        v-on:mousedown="startDrag"
        v-on:mouseup="endDrag"
        v-on:mousecancel="endDrag"
        v-on:mouseleave="endDrag"
        v-on:touchstart="startTouch"
        v-on:touchend="endTouch"
        v-on:touchleave="endTouch"
        v-on:touchcancel="endTouch">
        <SvgDefinitions></SvgDefinitions>
        <g ref="viewport">
            <g ref="underlay">
            </g>
            <g ref="window">
            </g>
        </g>
    </svg>
</template>
<script>
import MiniSearch from 'minisearch';
import SvgDefinitions from './SvgDefinitions.vue';
import { createText, createRect } from '@/scripts/object.js';

const HIDDEN = new Set(['Lookup', 'Regex', 'Configuration', 'Provider']);

export default {
    name: 'SvgCanvas',
    props: {
        bus: Object,
        layer: String,
        context: Object,
        metrics: Map,
        search: String,
        scores: Object,
        selected: Object,
        selectable: Boolean,
        multi: Boolean,
        readonly: Boolean,
    },
    components: {
        SvgDefinitions,
    },
    data() {
        return {
            pan: null,
            mouse: null,
            scale: 1,
            items: new Map(),
            groups: new Map(),
            external: new Map(),
            grouping: new Map(),
            annotations: new Map(),
            offset: null,
            tp: new Array(),
            pending: null,
            isTouch: false,
            minisearch: null,
            active: null,
            last: null,
            highlighted: null,
            selection: null,
        };
    },
    mounted() {
        this.bus.$on('center', this.onCenter);
        this.bus.$on('load', this.onChange);
        this.bus.$on('moved', this.onMove);
        this.bus.$on('multimoved', this.onMultiMove);
        this.bus.$on('created', this.onCreate);
        this.bus.$on('updated', this.onUpdate);
        this.bus.$on('deleted', this.onDelete);
        if (!this.pan) {
            this.pan = this.$refs.editor.createSVGTransform();
            this.$refs.viewport.transform.baseVal.insertItemBefore(this.pan, 0);
            this.mouse = this.$refs.editor.createSVGPoint();
        }
        this.redraw();
    },
    destroyed() {
        this.bus.$off('center', this.onCenter);
        this.bus.$off('load', this.onChange);
        this.bus.$off('moved', this.onMove);
        this.bus.$off('created', this.onCreate);
        this.bus.$off('updated', this.onUpdate);
        this.bus.$off('deleted', this.onDelete);
        this.bus.$off('multimoved', this.onMultiMove);
    },
    computed: {
        viewBox() {
            return `0 0 ${2*this.width} ${2*this.width}`;
        },
        width() {
            const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            return Math.round(Math.sqrt(w*w + h*h)/2);
        }
    },
    watch: {
        metrics() {
            let error = false;
            for (const item of this.items.values()) {
                if (this.metrics && this.metrics.has(item.id)) {
                    if (this.metrics.get(item.id).errors > 0) {
                        error = true;
                        item.element.classList.add('invalid');
                        item.element.classList.remove('warning');
                    } else if (this.metrics.get(item.id).warnings > 0) {
                        item.element.classList.add('warning');
                        item.element.classList.remove('invalid');
                    } else {
                        item.element.classList.remove('warning');
                        item.element.classList.remove('invalid');
                    }
                } else {
                    item.element.classList.remove('warning');
                    item.element.classList.remove('invalid');
                }
            }
            if (error) {
                this.$refs.editor.classList.add('invalid');
            } else {
                this.$refs.editor.classList.remove('invalid');
            }
        },
        layer() {
            this.redraw();

            if (this.selectable && this.highlighted) {
                this.$refs.editor.classList.add(this.highlighted.category);
            } else if (this.highlighted) {
                this.$refs.editor.classList.remove(this.highlighted.category);
            }
            const target = this.external.get(this.selected?.id);
            this.active = this.select(target, true);
        },
        search() {
            this.doSearch();
        },
        selected() {
            const target = this.external.get(this.selected?.id);
            this.active = this.select(target, true);
        },
        selectable() {
            if (this.selectable && this.highlighted) {
                this.$refs.editor.classList.add(this.highlighted.category);
            } else if (this.highlighted) {
                this.$refs.editor.classList.remove(this.highlighted.category);
            }
            const target = this.external.get(this.selected?.id);
            this.active = this.select(target, true);
        },
        multi() {
            this.clearMultiSelect();
        },
        scores() {
            if (this.scores) this.doScale(this.scores);
            else {
                this.items.forEach(item => {
                    item.element.classList.remove('disabled');
                    item.resize.setScale(1, 1);
                    item.resizeTitle.setScale(1, 1);
                });
            }
        },
    },
    methods: {
        onCenter(position) {
            if (position) {
                this.pan.setTranslate(this.width - position.x - 30, this.width - position.y - 30);
            } else {
                let xMin = Infinity;
                let yMin = Infinity;
                for (const { position } of this.items.values()) {
                    xMin = Math.min(xMin, position.x);
                    yMin = Math.min(yMin, position.y);
                }
                xMin = xMin == Infinity ? 0 : xMin;
                yMin = yMin == Infinity ? 0 : yMin;
                this.pan.setTranslate(320 - xMin, 440 - yMin);
            }
        },
        onChange(id) {
            while (this.context.has(id))
            {
                const item = this.context.get(id);
                if (this.external.has(item.id)) {
                    const element = this.external.get(item.id);
                    const obj = this.items.get(element);
                    obj.edges.forEach((value, key) => {
                        this.items.get(key).edges.delete(obj.element);
                        obj.viewport.removeChild(value.element);
                    });
                    obj.edges.clear();
                    obj.incoming.forEach((value, key) => {
                        this.items.get(key).outgoing.delete(obj.element);
                        obj.viewport.removeChild(value.incoming);
                        obj.viewport.removeChild(value.outgoing);
                    });
                    obj.incoming.clear();
                    obj.outgoing.forEach((value, key) => {
                        this.items.get(key).incoming.delete(obj.element);
                        obj.viewport.removeChild(value.incoming);
                        obj.viewport.removeChild(value.outgoing);
                    });
                    obj.outgoing.clear();
                    const affected = this.annotations.get(obj.id);
                    if (affected) {
                        this.annotations.delete(obj.id);
                        affected.forEach(downstream => {
                            this.annotations.get(downstream).delete(obj.id);
                            var ref = this.context.get(downstream);
                            if (ref) {
                                this.annotate(ref);
                            }
                        });
                    }

                    this.annotate(item);
                    item.references.forEach((incoming, target) => {
                        if (this.external.has(target)) {
                            this.link({
                                src: incoming ? this.external.get(target) : obj.element,
                                dst: incoming ? obj.element : this.external.get(target),
                                exists: true,
                            });
                        }
                    });
                    break;
                }
                id = item.parent;
            }
        },
        onCreate(cmd) {
            if (this.layer === cmd.parent) {
                this.clearMultiSelect();
                if (!HIDDEN.has(cmd.category)) {
                    let x = -Infinity;
                    let y = -Infinity;
                    if (cmd.position) {
                        x = cmd.position.x;
                        y = cmd.position.y;
                    } else {
                        let xMin = Infinity;
                        let yMin = Infinity;
                        let xMax = -Infinity;
                        let yMax = -Infinity;
                        for (const item of this.items.values()) {
                            xMin = Math.min(xMin, item.position.x);
                            yMin = Math.min(yMin, item.position.y);
                            xMax = Math.max(xMax, item.position.x);
                            yMax = Math.max(yMax, item.position.y);
                            if (item.position.y > y) {
                                y = item.position.y;
                                x = item.position.x + 200;
                            } else if (item.position.y == y) {
                                x = Math.max(x, item.position.x + 200);
                            }
                        }
                        if (x == -Infinity) {
                            x = 0;
                            y = 0;
                        } else if (x > xMax && x > xMin + 1200) {
                            x = xMin;
                            y = yMax + 200;
                        }
                    }
                    const obj = this.addItem(this.$refs.window, cmd.id, cmd.category, x, y, cmd.group, cmd.readonly);
                    obj.update(this.context.label(cmd.id));
                    cmd.references.forEach((incoming, target) => {
                        if (this.external.has(target)) {
                            this.link({ src: incoming ? this.external.get(target) : obj.element, dst: incoming ? obj.element : this.external.get(target), exists: true });
                        }
                    });
                    this.annotate(cmd);
                    if (cmd.group) {
                        this.redrawBoundaries();
                        this.groups.get(cmd.group).toggle(true);
                    }
                }
            } else {
                this.onChange(cmd.id);
            }
        },
        onUpdate(cmd) {
            if (this.external.has(cmd.id)) {
                this.clearMultiSelect();
                const element = this.external.get(cmd.id);
                const obj = this.items.get(element);
                obj.edges.forEach((value, key) => {
                    this.items.get(key).edges.delete(obj.element);
                    obj.viewport.removeChild(value.element);
                });
                obj.edges.clear();
                obj.incoming.forEach((value, key) => {
                    this.items.get(key).outgoing.delete(obj.element);
                    obj.viewport.removeChild(value.incoming);
                    obj.viewport.removeChild(value.outgoing);
                });
                obj.incoming.clear();
                obj.outgoing.forEach((value, key) => {
                    this.items.get(key).incoming.delete(obj.element);
                    obj.viewport.removeChild(value.incoming);
                    obj.viewport.removeChild(value.outgoing);
                });
                obj.outgoing.clear();
                obj.update(this.context.label(cmd.id));
                const affected = this.annotations.get(obj.id);
                if (affected) {
                    this.annotations.delete(obj.id);
                    affected.forEach(downstream => {
                        this.annotations.get(downstream).delete(obj.id);
                        var ref = this.context.get(downstream);
                        if (ref) {
                            this.annotate(ref);
                        }
                    });
                }
                cmd.references.forEach((incoming, target) => {
                    if (this.external.has(target)) {
                        this.link({ src: incoming ? this.external.get(target) : obj.element, dst: incoming ? obj.element : this.external.get(target), exists: true });
                    }
                });
                this.annotate(cmd);
                if (cmd.group) {
                    this.redrawBoundaries();
                    this.groups.get(cmd.group).toggle(true);
                }
            } else {
                this.onChange(cmd.id);
            }
        },
        onDelete(cmd) {
            if (this.external.has(cmd.id)) {
                this.clearMultiSelect();
                const element = this.external.get(cmd.id);
                const obj = this.items.get(element);
                obj.edges.forEach((value, key) => {
                    this.items.get(key).edges.delete(obj.element);
                    obj.viewport.removeChild(value.element);
                });
                obj.incoming.forEach((value, key) => {
                    this.items.get(key).outgoing.delete(obj.element);
                    obj.viewport.removeChild(value.incoming);
                    obj.viewport.removeChild(value.outgoing);
                });
                obj.outgoing.forEach((value, key) => {
                    this.items.get(key).incoming.delete(obj.element);
                    obj.viewport.removeChild(value.incoming);
                    obj.viewport.removeChild(value.outgoing);
                });
                this.items.delete(obj.element);
                this.external.delete(obj.id);
                obj.viewport.removeChild(obj.g);
                this.active = this.select();
                this.offset = null;
                if (obj.group) {
                    this.redrawBoundaries();
                }
            } else {
                this.onChange(cmd.id);
            }
        },
        onMove(cmd) {
            const element = this.external.get(cmd.id);
            const obj = this.items.get(element);
            if (obj) {
                obj.drag(cmd.position.x - obj.center.x, cmd.position.y - obj.center.y);
                if (cmd.group || obj.group) {
                    obj.group = cmd.group;
                    this.redrawBoundaries();
                    if (cmd.group) {
                        this.groups.get(cmd.group).toggle(true);
                    }
                }
            }
        },
        onMultiMove(cmd) {
            let grouped = false;
            this.clearMultiSelect();
            cmd.positions.forEach(moved => {
                const element = this.external.get(moved.id);
                const obj = this.items.get(element);
                if (obj) {
                    obj.drag(moved.position.x - obj.center.x, moved.position.y - obj.center.y);
                    if (obj.group || moved.group) {
                        grouped = true;
                        obj.group = moved.group;
                    }
                }
            });
            this.active = null;
            if (grouped) {
                this.redrawBoundaries();
            }
        },
        redraw() {
            while (this.$refs.underlay.lastChild) {
                this.$refs.underlay.removeChild(this.$refs.underlay.lastChild);
            }
            while (this.$refs.window.lastChild) {
                this.$refs.window.removeChild(this.$refs.window.lastChild);
            }
            this.items.clear();
            this.groups.clear();
            this.external.clear();
            this.annotations.clear();
            this.offset = null;
            this.tp = new Array();
            this.pending = null;
            this.isTouch = false;
            this.minisearch = null;
            this.active = null;
            this.last = null;

            const objects = this.context.objects(this.layer).filter(obj => obj.values && !HIDDEN.has(obj.category));
            if (this.injectObjects(this.$refs.window, objects)) {
                this.$refs.editor.classList.add('invalid');
            } else {
                this.$refs.editor.classList.remove('invalid');
            }
            objects.forEach(this.annotate);
            this.redrawBoundaries();
            if (this.scores) this.doScale(this.scores);
            else if (this.search) this.doSearch();
        },
        injectObjects(viewport, objects, boundaries) {
            const unknown = [];
            let xMin = Infinity;
            let yMin = Infinity;
            let xMax = -Infinity;
            let yMax = -Infinity;
            let invalid = false;
            for (const item of objects) {
                if (item.position) {
                    xMin = Math.min(xMin, item.position.x);
                    yMin = Math.min(yMin, item.position.y);
                    xMax = Math.max(xMax, item.position.x);
                    yMax = Math.max(yMax, item.position.y);
                    const selected = this.addItem(
                        viewport,
                        item.id,
                        item.category,
                        item.position.x,
                        item.position.y,
                        item.group,
                        item.readonly);
                    if (item.errors.length || (this.metrics && this.metrics.has(item.id) && this.metrics.get(item.id).errors > 0)) {
                        invalid = true;
                        selected.element.classList.add('invalid');
                    } else if (this.metrics && this.metrics.has(item.id) && this.metrics.get(item.id).warnings > 0) {
                        selected.element.classList.add('warning');
                    }
                    selected.update(this.context.label(item.id));
                } else unknown.push(item);
            }
            xMin = xMin == Infinity ? 0 : xMin;
            yMin = yMin == Infinity ? 0 : yMin;
            xMax = xMax > xMin + 1200 ? xMax : xMin + 1200;
            yMax = yMax == -Infinity ? 0 : yMax;
            this.pan.setTranslate(320 - xMin, 440 - yMin);
            let x = xMin;
            for (const item of unknown) {
                const selected = this.addItem(
                    viewport,
                    item.id,
                    item.category,
                    x,
                    yMax + 200,
                    item.group,
                    item.readonly);
                if (item.errors.size || (this.metrics && this.metrics.has(item.id) && this.metrics.get(item.id).errors > 0)) {
                    invalid = true;
                    selected.element.classList.add('invalid');
                } else if (this.metrics && this.metrics.has(item.id) && this.metrics.get(item.id).warnings > 0) {
                    selected.element.classList.add('warning');
                }
                selected.update(this.context.label(item.id));
                x += 200;
                if (x > xMax) {
                    x = xMin;
                    yMax += 200;
                }
            }

            objects.forEach(item => {
                const dst = this.external.get(item.id);
                item.references.forEach((incoming, target) => {
                    if (this.external.has(target)) {
                        this.link({ src: incoming ? this.external.get(target) : dst, dst: incoming ? dst : this.external.get(target), exists: true });
                    }
                });
            });

            if (boundaries) {
                const local = new Map();
                objects.forEach(item => {
                    if (item.group && local.has(item.group)) {
                        local.get(item.group).add(item);
                    } else if (item.group) {
                        const group = this.createGroup(viewport, item.group, item.position);
                        group.add(item);
                        local.set(item.group, group);
                    }
                });

                local.forEach(item => {
                    item.redraw();
                    viewport.appendChild(item.element);
                });
            }
            return invalid;
        },
        redrawBoundaries(hideAll) {
            while (this.$refs.underlay.lastChild) {
                this.$refs.underlay.removeChild(this.$refs.underlay.lastChild);
            }
            this.groups.clear();
            this.grouping.clear();

            this.items.forEach(item => {
                if (item.group && this.groups.has(item.group)) {
                    this.groups.get(item.group).add(item);
                } else if (item.group) {
                    if (hideAll) {
                        item.hide();
                    }
                    const group = this.createGroup(item.viewport, item.group, item.position, hideAll || item.hidden);
                    group.add(item);
                    this.groups.set(item.group, group);
                }
            });
            this.groups.forEach((item, group) => {
                const element = item.redraw();
                this.grouping.set(element, group);
                this.$refs.underlay.appendChild(item.element);
            });
        },
        onContextMenu(evt) {
            if (this.readonly || (this.active && this.active.moved) || (this.pending && this.pending.suppress)) {
                return;
            }

            var selected;
            var group = this.grouping.get(evt.target) || '';
            var captured = [];
            if (this.items.has(this.selection)) {
                var multi = this.items.get(this.selection);
                selected = null;
                captured.push(...multi.captured.map(x => x.id));
            } else if (!this.selectable && this.items.has(evt.target)) {
                selected = this.items.get(evt.target).id;
            } else if (this.active && !this.isTouch && !group) {
                selected = this.active.id;
            } else if (group) {
                selected = null;
                captured.push(...this.groups.get(group).captured.map(x => x.id));
            } else if (!this.active) {
                selected = null;
            }
            if (selected !== undefined) {
                this.prepareMousePosition(evt.clientX, evt.clientY);
                var point = this.mouse.matrixTransform(this.pan.matrix.inverse());
                document.activeElement.blur();
                this.$emit('context', {
                    id: selected,
                    x: point.x,
                    y: point.y,
                    captured,
                    group,
                });
            }
        },
        onScroll(evt) {
            if (!this.items.size) {
                return;
            }
            evt.preventDefault();
            let delta = evt.wheelDelta ? (0.0015 * evt.wheelDelta) : (-0.0033*evt.deltaY);
            if (evt.deltaMode == 1 && !evt.ctrlKey)
            {
                delta = delta * 3;
            }
            else if (evt.deltaMode != 1 && evt.ctrlKey)
            {
                delta = delta / 3;
            }
            this.prepareMousePosition(evt.clientX, evt.clientY);
            this.doScroll(Math.min(10 / this.pan.matrix.a, Math.max(0.1 / this.pan.matrix.a, Math.exp(delta))));
        },
        startDrag(evt) {
            if (this.isTouch || !this.items.size) {
                return;
            }

            this.active = this.select(evt.target);
            if (evt.buttons !== 1) {
                return;
            } else if (this.active && this.active === this.last) {
                this.active.toggle();
                this.active = null;
                return;
            }

            this.last = this.active;
            if (this.active) {
                setTimeout(() =>{
                    this.last = null;
                }, 220);
                this.active.start();
                this.prepareMousePosition(evt.clientX, evt.clientY);
                this.offset = this.active.center;
            } else {
                this.prepareMousePosition(evt.clientX, evt.clientY);
                this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
                if (this.multi) {
                    this.startMulti();
                }
            }
            this.$refs.editor.addEventListener('mousemove', this.drag);
        },
        drag(evt) {
            if (this.isTouch || this.last) {
                return;
            }

            evt.preventDefault();
            if (this.active) {
                this.prepareMousePosition(evt.clientX, evt.clientY);
                this.active.drag(
                    (this.mouse.x - this.pan.matrix.e)/this.pan.matrix.a - this.active.transform.matrix.a*this.offset.x,
                    (this.mouse.y - this.pan.matrix.f)/this.pan.matrix.d - this.active.transform.matrix.d*this.offset.y,
                    evt.target);
            } else if (this.multi) {
                this.prepareMousePosition(evt.clientX, evt.clientY);
                this.doMulti();
            } else {
                this.prepareMousePosition(evt.clientX, evt.clientY);
                this.doPan();
            }
        },
        endDrag(evt) {
            if (this.isTouch) {
                this.isTouch = false;
                return;
            } else if (this.active && !this.active.origin) {
                return;
            }

            evt.preventDefault();

            if (this.active) {
                this.active.end();
            } else if (this.multi && this.selection && !this.items.get(this.selection)) {
                this.endMulti();
            }
            this.active = null;
            this.offset = null;
            this.$refs.editor.removeEventListener('mousemove', this.drag);
        },
        startTouch(evt) {
            if (!this.items.size) {
                return;
            }

            this.isTouch = true;
            this.tp.splice(-1, 0, ...evt.changedTouches);
            this.resetTouchCoordinates(evt.touches);
            if (evt.touches.length === 1) {
                if (this.active) {
                    this.offset = this.active.center;
                    if (evt.target !== this.active.element) {
                        evt.preventDefault();
                        this.prepareTouch(evt);
                    } else if (this.active === this.last) {
                        evt.preventDefault();
                        this.active.toggle();
                        this.active = null;
                        return;
                    } else {
                        this.active.moved = false;
                        this.last = this.active;
                        setTimeout(() =>{
                            this.last = null;
                        }, 220);
                    }
                } else {
                    this.active = this.select(evt.target);
                    if (this.active) {
                        this.last = this.active;
                        setTimeout(() =>{
                            this.last = null;
                        }, 220);

                        this.offset = this.active.captured ? this.mouse.matrixTransform(this.pan.matrix.inverse()) : this.active.center;
                        this.prepareTouch(evt);
                    } else if (this.multi) {
                        evt.preventDefault();
                        this.prepareMousePosition(evt.touches[0].clientX, evt.touches[0].clientY);
                        this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
                        this.startMulti();
                    }
                }

                this.$refs.editor.addEventListener('touchmove', this.touch);
            }
        },
        prepareTouch(evt) {
            const target = evt.target === this.active.element ? this.$refs.editor : evt.target
            const x0 = evt.touches[0].clientX;
            const y0 = evt.touches[0].clientY;
            const threshold = target.clientWidth * target.clientWidth / 100;

            this.prepareMousePosition(x0, y0);
            this.active.moved = false;
            this.pending = {
                suppress: evt.target !== this.active.element,
                target: evt.target,
                timer: setTimeout(() => {
                    if (evt.target !== this.active.element) {
                        this.active.start();
                        this.active.drag(
                            (this.mouse.x - this.pan.matrix.e)/this.pan.matrix.a - this.active.transform.matrix.a*this.offset.x,
                            (this.mouse.y - this.pan.matrix.f)/this.pan.matrix.d - this.active.transform.matrix.d*this.offset.y,
                            target);
                        this.pending.timer = null;
                    }
                }, 350),
                listener: (evt2) => {
                    const dx = evt2.touches[0].clientX - x0;
                    const dy = evt2.touches[0].clientY - y0;
                    if (dx*dx + dy*dy > threshold) {
                        evt2.preventDefault();
                        this.commitTouch(true);
                    }
                },
                pan: (x, y) => {
                    const dx = x - x0;
                    const dy = y - y0;
                    if (dx*dx + dy*dy > threshold) {
                        this.commitTouch(true);
                        this.prepareMousePosition(x0, y0);
                        this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
                        if (this.multi) {
                            this.clearMultiSelect();
                            this.startMulti();
                        }
                    }
                },
            };
        },
        commitTouch(canceled) {
            if (!this.pending) {
                return;
            }

            if (this.pending.timer !== null) {
                clearTimeout(this.pending.timer);
                if (!canceled) {
                    this.active = this.select(this.pending.target);
                    if (this.active) {
                        this.offset = this.active.center;
                        this.last = this.active;
                        setTimeout(() =>{
                            this.last = null;
                        }, 220);
                    }
                }
            } else {
                const changed = this.active.end(canceled);
                if (!changed && !canceled) {
                    const point = this.mouse.matrixTransform(this.pan.matrix.inverse());
                    this.$emit('context', {
                        id: this.active.id ?? null,
                        x: point.x,
                        y: point.y,
                        captured: this.active.id ? [] : this.active.captured.map(x => x.id),
                        group: this.active.group || '',
                    });
                }
            }
            this.pending = null;
        },
        touch(evt) {
            if (this.last) {
                return;
            }

            evt.preventDefault();

            if (evt.touches.length > 1) {
                // Note that this assumes a stable order for touches and tp
                // TODO: remove this assumption everywhere
                const x0 = evt.touches[0].clientX;
                const x1 = evt.touches[1].clientX;
                const y0 = evt.touches[0].clientY;
                const y1 = evt.touches[1].clientY;
                this.prepareMousePosition(0.5*(x0 + x1), 0.5*(y0 + y1));
                this.doPan();
                this.doScroll(Math.min(2, Math.max(0.01, this.scale * Math.sqrt(Math.pow(x1-x0, 2) + Math.pow(y1-y0, 2)))) / this.pan.matrix.a);
            } else if (this.pending) {
                this.pending.pan(evt.touches[0].clientX, evt.touches[0].clientY);
            } else if (this.multi) {
                this.prepareMousePosition(evt.touches[0].clientX, evt.touches[0].clientY);
                this.doMulti();
            } else {
                this.prepareMousePosition(evt.touches[0].clientX, evt.touches[0].clientY);
                this.doPan();
            }
        },
        endTouch(evt) {
            const exists = new Set();
            for (const obj of evt.touches) {
                exists.add(obj.identifier);
            }
            this.tp = this.tp.filter(obj => exists.has(obj.identifier));
            if (evt.touches.length >= 2) {
                this.resetTouchCoordinates(evt.touches);
                evt.preventDefault();
            } else if (evt.touches.length) {
                this.$refs.editor.removeEventListener('touchmove', this.touch);
                evt.preventDefault();
            } else if (this.selection) {
                if (!this.items.get(this.selection)) {
                    this.active = this.endMulti();
                } else {
                    this.commitTouch();
                }
                this.isTouch = false;
                evt.preventDefault();
            } else if (this.active) {
                this.commitTouch();
                this.isTouch = false;
                evt.preventDefault();
            }
        },
        startMulti() {
            if (this.selection) {
                this.selection.setAttribute('x', this.offset.x);
                this.selection.setAttribute('y', this.offset.y);
                this.selection.setAttribute('width', 0);
                this.selection.setAttribute('height', 0);
                this.selection.setAttribute('pointer-events', 'none');
                this.selection.setAttribute('class', 'selection outline');
                this.selection.transform.baseVal.clear();
                this.items.delete(this.selection);
                this.$refs.window.insertBefore(this.selection, this.$refs.window.firstChild);
            } else {
                this.selection = createRect(this.offset.x, this.offset.y, 0, 0, 'selection outline');
                this.selection.setAttribute('pointer-events', 'none');

                this.$refs.window.insertBefore(this.selection, this.$refs.window.firstChild);
            }
        },
        doMulti() {
            const x = (this.mouse.x - this.pan.matrix.e)/this.pan.matrix.a;
            const y = (this.mouse.y - this.pan.matrix.f)/this.pan.matrix.d;
            if (x >= this.offset.x) {
                this.selection.setAttribute('x', this.offset.x);
                this.selection.setAttribute('width', x - this.offset.x);
            } else {
                this.selection.setAttribute('x', x);
                this.selection.setAttribute('width', this.offset.x - x);
            }

            if (y >= this.offset.y) {
                this.selection.setAttribute('y', this.offset.y);
                this.selection.setAttribute('height', y - this.offset.y);
            } else {
                this.selection.setAttribute('y', y);
                this.selection.setAttribute('height', this.offset.y - y);
            }
        },
        endMulti() {
            const xMin = this.selection.x.baseVal.value;
            const xMax = this.selection.x.baseVal.value + this.selection.width.baseVal.value;
            const yMin = this.selection.y.baseVal.value;
            const yMax = this.selection.y.baseVal.value + this.selection.height.baseVal.value;
            const captured = Array.from(this.items).filter(v => {
                const position = v[1].center.matrixTransform(v[1].transform.matrix);
                return v.element !== this.selection
                    && position.x >= xMin
                    && position.x <= xMax
                    && position.y >= yMin
                    && position.y <= yMax;
            }).map(v => v[1]);

            if (captured.length > 0) {
                const center = this.$refs.editor.createSVGPoint();
                center.x = this.selection.x.baseVal.value + this.selection.width.baseVal.value / 2;
                center.y = this.selection.y.baseVal.value + this.selection.height.baseVal.value / 2;

                this.selection.removeAttribute('pointer-events');
                this.selection.setAttribute('class', 'selection active');

                const transform = this.$refs.editor.createSVGTransform();
                this.selection.transform.baseVal.clear();
                this.selection.transform.baseVal.insertItemBefore(transform, 0);

                const item = {
                    parent: this,
                    captured,
                    svg: this.$refs.editor,
                    element: this.selection,
                    origin: null,
                    center: center,
                    transform: transform,
                    moved: false,
                    toggle() {},
                    start() {
                        this.origin = { x: this.transform.matrix.e, y: this.transform.matrix.f };
                        this.svg.classList.add('dragging');
                        this.element.classList.add('ghost');
                        this.moved = false;
                    },
                    drag(dx, dy) {
                        const sx = Math.round(Math.round(dx/40)*40);
                        const sy = Math.round(Math.round(dy/40)*40);

                        const cx = sx - this.transform.matrix.e;
                        const cy = sy - this.transform.matrix.f;
                        this.transform.setTranslate(sx, sy);
                        this.captured.forEach(x => x.drag(x.transform.matrix.e + cx, x.transform.matrix.f + cy));
                    },
                    end(reset) {
                        const shifted = Math.abs(
                                this.transform.matrix.e - this.origin.x
                            ) >= 40 || Math.abs(
                                this.transform.matrix.f - this.origin.y
                            ) >= 40;
                        this.svg.classList.remove('dragging');
                        if (reset || !shifted) {
                            this.drag(this.origin.x, this.origin.y);
                        } else {
                            this.moved = true;
                        }

                        this.element.classList.remove('ghost');
                        if (!reset && shifted) {
                            const positions = this.captured.map(x => {
                                const position = x.center.matrixTransform(x.transform.matrix);
                                return {
                                    id: x.id,
                                    x: position.x,
                                    y: position.y,
                                }
                            });
                            this.parent.$emit('multimove', {
                                positions,
                                x: this.transform.matrix.e,
                                y: this.transform.matrix.f,
                            });
                        }
                        this.origin = null;
                        return shifted && !reset;
                    }
                };
                this.items.set(this.selection, item);
                return item;
            } else {
                this.clearMultiSelect();
                return null;
            }
        },
        prepareMousePosition(x, y) {
            const CTM = this.$refs.editor.getScreenCTM();
            this.mouse.x = (x - CTM.e) / CTM.a;
            this.mouse.y = (y - CTM.f) / CTM.d;
        },
        doScroll(factor) {
            this.pan.matrix.a *= factor;
            this.pan.matrix.b *= factor;
            this.pan.matrix.c *= factor;
            this.pan.matrix.d *= factor;
            this.pan.matrix.e = (1-factor)*this.mouse.x + factor*this.pan.matrix.e;
            this.pan.matrix.f = (1-factor)*this.mouse.y + factor*this.pan.matrix.f;
            this.$emit('translate', {
                scale: this.pan.matrix.a,
                x: this.pan.matrix.e,
                y: this.pan.matrix.f,
            });
        },
        doPan() {
            this.pan.matrix.e = this.mouse.x - this.pan.matrix.a*this.offset.x;
            this.pan.matrix.f = this.mouse.y - this.pan.matrix.d*this.offset.y;
            this.$emit('translate', {
                scale: this.pan.matrix.a,
                x: this.pan.matrix.e,
                y: this.pan.matrix.f,
            });
        },
        doSearch() {
            if (!this.search) {
                this.items.forEach(item => {
                    item.element.classList.remove('disabled');
                    item.resize.setScale(1, 1);
                    item.resizeTitle.setScale(1, 1);
                });
                return;
            }

            if (!this.minisearch) {
                this.minisearch = new MiniSearch({
                    fields: ['name', 'description', 'category', 'subject', 'tenant', 'claim', 'group'],
                    searchOptions: {
                        boost: { name: 3, claim: 2, category: 2 },
                        fuzzy: 0.2,
                        prefix: true,
                    },
                });
                const objects = this.context.objects(this.layer).filter(obj => obj.values && !HIDDEN.has(obj.category));
                this.minisearch.addAll(objects.map(x => ({...x.values, category: x.category, id: x.id})));
            }

            const scores = new Map();
            const results = this.minisearch.search(this.search);
            let max = 0;
            let min = Infinity;
            results.forEach(obj => {
                max = Math.max(max, obj.score);
                min = Math.min(min, obj.score);
                scores.set(obj.id, obj.score);
            });
            this.doScale({ max, min, scores });
        },
        doScale({ max, min, scores }) {
            if (!scores.size) {
                this.items.forEach(item => {
                    item.element.classList.remove('disabled');
                    item.resize.setScale(1, 1);
                    item.resizeTitle.setScale(1, 1);
                });
            } else {
                const width = Math.max(max - min, 1);
                const alpha = width / 2 + max - width;

                this.items.forEach(item => {
                    let scale = 0.4;
                    if (scores.has(item.id)) {
                        item.element.classList.remove('disabled');
                        scale = 0.5 + 1 / (1 + Math.exp(6 * (alpha - scores.get(item.id)) / width));
                    } else {
                        item.element.classList.add('disabled');
                    }
                    item.resize.matrix.a = scale;
                    item.resize.matrix.e = item.center.x * (1 - scale);
                    item.resize.matrix.d = scale;
                    item.resize.matrix.f = item.center.y * (1 - scale);
                    item.resizeTitle.matrix.a = scale;
                    item.resizeTitle.matrix.e = item.center.x * (1 - scale);
                    item.resizeTitle.matrix.d = scale;
                    item.resizeTitle.matrix.f = item.center.y * (1 - scale);
                });
            }
        },
        resetTouchCoordinates(touches) {
            if (this.tp.length == 1) {
                this.prepareMousePosition(touches[0].clientX, touches[0].clientY);
                this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
            } else {
                const x = 0.5 * (touches[0].clientX + touches[1].clientX);
                const y = 0.5 * (touches[0].clientY + touches[1].clientY);
                this.prepareMousePosition(x, y);
                this.offset = this.mouse.matrixTransform(this.pan.matrix.inverse());
                this.scale = this.pan.matrix.a / Math.sqrt(Math.pow(
                    this.tp[1].clientX - this.tp[0].clientX,
                    2) + Math.pow(
                    this.tp[1].clientY - this.tp[0].clientY,
                    2));
            }
        },
        annotate(obj) {
            if (!this.annotations.has(obj.id)) {
                this.annotations.set(obj.id, new Set());
            }
            for (const target of obj.links) {
                this.tryLink(obj.id, target[0], target[1]);
            }
            for (const target of this.context.links(obj.id)) {
                this.tryLink(obj.id, target[0], target[1]);
            }
        },
        tryLink(lhs, rhs, ref) {
            if (this.external.has(lhs) && this.external.has(rhs)) {
                const src = this.items.get(this.external.get(lhs));
                const dst = this.items.get(this.external.get(rhs));
                if (this.annotations.has(lhs)) {
                    this.annotations.get(lhs).add(rhs);
                } else {
                    this.annotations.set(lhs, new Set([rhs]));
                }
                if (this.annotations.has(rhs)) {
                    this.annotations.get(rhs).add(lhs);
                } else {
                    this.annotations.set(rhs, new Set([lhs]));
                }

                if (ref && !src.outgoing.has(dst.element)) {
                    this.createReference(src, dst);
                } else if (!ref) {
                    this.link({ src: src.element, dst: dst.element, exists: true });
                }
            }
        },
        createReference(src, dst) {
            const dst_position = dst.center.matrixTransform(dst.transform.matrix);
            const src_position = src.center.matrixTransform(src.transform.matrix);

            const outgoing = document.createElementNS('http://www.w3.org/2000/svg', 'line');
            outgoing.setAttribute('class', 'annotation');
            outgoing.setAttribute('x1', src_position.x);
            outgoing.setAttribute('y1', src_position.y);
            outgoing.setAttribute('x2', src_position.x);
            outgoing.setAttribute('y2', dst_position.y);

            const incoming = document.createElementNS('http://www.w3.org/2000/svg', 'line');
            incoming.setAttribute('class', 'annotation');
            incoming.setAttribute('x1', src_position.x);
            incoming.setAttribute('y1', dst_position.y);
            incoming.setAttribute('x2', dst_position.x);
            incoming.setAttribute('y2', dst_position.y);

            src.outgoing.set(dst.element, {
                incoming: incoming,
                outgoing: outgoing,
                update: function(x, y) {
                    this.outgoing.setAttribute('x1', x);
                    this.outgoing.setAttribute('y1', y);
                    this.outgoing.setAttribute('x2', x);
                    this.incoming.setAttribute('x1', x);
                },
            });

            dst.incoming.set(src.element, {
                incoming: incoming,
                outgoing: outgoing,
                update: function(x, y) {
                    this.outgoing.setAttribute('y2', y);
                    this.incoming.setAttribute('y1', y);
                    this.incoming.setAttribute('x2', x);
                    this.incoming.setAttribute('y2', y);
                },
            });
            src.viewport.insertBefore(incoming, src.viewport.firstChild);
            src.viewport.insertBefore(outgoing, src.viewport.firstChild);
        },
        updatePath: function(path, x1, y1, x2, y2) {
            const alpha = (x2 - x1) / Math.sqrt((x2 - x1)*(x2 - x1) + (y2 - y1)*(y2 - y1));
            const dy = (Math.sign(y2-y1) || 1) * 20 * alpha;
            const dx = 20 * Math.sqrt(1-alpha*alpha);
            path.setAttribute('d', `M${x2} ${y2} L${x1 + dx} ${y1 - dy} L${x1 - dx} ${y1 + dy} Z`);
        },
        link(evt) {
            const src = this.items.get(evt.src);
            const dst = this.items.get(evt.dst);
            const edge = dst.edges.get(src.element);
            if (edge && edge.state == 1 && evt.exists) {
                return;
            } else if (edge) {
                src.viewport.removeChild(src.edges.get(dst.element).element);
            }

            if (evt.exists) {
                const link = this.createLink(src, dst);
                src.edges.set(dst.element, link.src);
                dst.edges.set(src.element, link.dst);
                src.viewport.insertBefore(link.element, src.viewport.firstChild);
                if (this.highlighted === dst) {
                    link.src.activate();
                }
            } else {
                src.edges.delete(dst.element);
                dst.edges.delete(src.element);
            }
        },
        clearMultiSelect() {
            if (this.selection && this.selection.parentNode) {
                this.$refs.window.removeChild(this.selection);
                this.items.delete(this.selection);
            }
            this.selection = null;
        },
        select(target, suppress) {
            if (this.selection && this.selection === target) {
                return this.items.get(target);
            }

            const proposed = this.items.get(target);
            if (this.highlighted && this.highlighted !== proposed) {
                this.highlighted.element.classList.remove('active');
                this.$refs.editor.classList.remove(this.highlighted.category);
                this.highlighted.edges.forEach((value) => {
                    value.element.classList.remove('active');
                });
            } else if (this.active) {
                this.active.element.classList.remove('active');
            }

            if (proposed && this.selectable) {
                this.highlighted = proposed;
                this.highlighted.element.classList.add('active');
                this.$refs.editor.classList.add(this.highlighted.category);
                while (this.highlighted.g.nextSibling) {
                    this.highlighted.viewport.insertBefore(
                        this.highlighted.g.nextSibling,
                        this.highlighted.g);
                }
                this.highlighted.edges.forEach((value) => {
                    if (value.state) {
                        value.element.classList.add('active');
                    }
                });
                if (!suppress) {
                    this.$emit('edit', this.context.get(this.highlighted.id));
                }
                return this.highlighted;
            } else if (this.highlighted) {
                this.highlighted = null;
                if (!suppress) {
                    this.$emit('edit', null);
                }
                return this.highlighted;
            } else if (this.grouping.has(target)) {
                this.clearMultiSelect();
                const group = this.groups.get(this.grouping.get(target));
                if (group.element.nextSibling) {
                    this.$refs.underlay.insertBefore(group.element, this.$refs.underlay.lastChild);
                }
                group.element.classList.add('active');
                return group;
            } else {
                return null;
            }
        },
        createGroup(viewport, group, position, hidden) {
            return {
                parent: this,
                captured: [],
                group: group,
                svg: this.$refs.editor,
                viewport: viewport,
                element: null,
                origin: null,
                center: null,
                transform: null,
                hidden,
                xMin: position.x,
                xMax: position.x,
                yMin: position.y,
                yMax: position.y,
                add(item) {
                    this.captured.push(item);
                    this.xMin = Math.min(this.xMin, item.position.x);
                    this.xMax = Math.max(this.xMax, item.position.x);
                    this.yMin = Math.min(this.yMin, item.position.y);
                    this.yMax = Math.max(this.yMax, item.position.y);
                    if (this.hidden && !item.hidden) {
                        item.hide();
                    } else if (!this.hidden && item.hidden) {
                        item.unhide();
                    }
                },
                redraw() {
                    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    const annotation = createText(
                        this.xMin - 200,
                        this.yMin - 130,
                        this.group,
                        'realm-label');
                    g.appendChild(annotation);
                    const rect = createRect(
                        this.xMin - 200,
                        this.yMin - 120,
                        this.xMax - this.xMin + 400,
                        this.yMax - this.yMin + 240,
                        'group');
                    g.appendChild(rect);
                    this.element = g;

                    this.center = this.svg.createSVGPoint();
                    this.center.x = this.xMin + (this.xMax - this.xMin) / 2;
                    this.center.y = this.yMin + (this.yMax - this.yMin) / 2;

                    this.transform = this.svg.createSVGTransform();
                    this.element.transform.baseVal.clear();
                    this.element.transform.baseVal.insertItemBefore(this.transform, 0);
                    return rect;
                },
                toggle(showAll) {
                    if (this.hidden || showAll) {
                        this.hidden = false;
                        this.captured.forEach(item => item.unhide());
                    } else {
                        this.hidden = true;
                        this.captured.forEach(item => item.hide());
                    }
                },
                start() {
                    this.origin = { x: this.transform.matrix.e, y: this.transform.matrix.f };
                    this.svg.classList.add('dragging');
                    this.element.classList.add('active');
                },
                drag(dx, dy) {
                    const sx = Math.round(Math.round(dx/40)*40);
                    const sy = Math.round(Math.round(dy/40)*40);

                    const cx = sx - this.transform.matrix.e;
                    const cy = sy - this.transform.matrix.f;
                    this.transform.setTranslate(sx, sy);
                    this.captured.forEach(x => x.drag(x.transform.matrix.e + cx, x.transform.matrix.f + cy));
                },
                end(reset) {
                    const shifted = Math.abs(
                            this.transform.matrix.e - this.origin.x
                        ) >= 40 || Math.abs(
                            this.transform.matrix.f - this.origin.y
                        ) >= 40;
                    this.svg.classList.remove('dragging');
                    this.element.classList.remove('active');
                    if (reset || !shifted) {
                        this.drag(this.origin.x, this.origin.y);
                    }

                    if (!reset && shifted) {
                        const positions = this.captured.map(x => {
                            const position = x.center.matrixTransform(x.transform.matrix);
                            return {
                                id: x.id,
                                x: position.x,
                                y: position.y,
                            }
                        });
                        this.parent.$emit('multimove', {
                            positions,
                        });
                    }
                    this.origin = null;
                    return shifted && !reset;
                },
            };
        },
        addItem(viewport, id, category, x, y, group, readonly) {
            const title = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            title.setAttribute('class', 'title');
            title.setAttribute('x', x);
            title.setAttribute('y', y - 70);

            const el = document.createElementNS('http://www.w3.org/2000/svg', 'use');
            el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `#${category}`);
            el.setAttribute('class', `draggable-sprite ${category}`);
            el.setAttribute('x', x);
            el.setAttribute('y', y);

            const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            g.appendChild(el);
            g.appendChild(title);
            viewport.appendChild(g);

            const center = this.$refs.editor.createSVGPoint();
            center.x = x;
            center.y = y;
            const parent = this;

            const item = {
                id,
                readonly,
                category,
                title,
                parent: this,
                viewport,
                g,
                svg: this.$refs.editor,
                origin: null,
                element: el,
                group,
                transform: this.$refs.editor.createSVGTransform(),
                resize: this.$refs.editor.createSVGTransform(),
                resizeTitle: this.$refs.editor.createSVGTransform(),
                center: center,
                position: center,
                edges: new Map(),
                incoming: new Map(),
                outgoing: new Map(),
                hover: null,
                cached: null,
                vertices: this.items,
                grouping: this.grouping,
                createLink: this.createLink,
                hidden: false,
                moved: false,
                dragging: false,
                update(title) {
                    this.title.textContent = title;
                },
                toggle() {
                    this.parent.$emit('focus', {id: this.id});
                },
                hide() {
                    if (!this.hidden) {
                        this.hidden = true;
                        this.g.classList.add('hidden');
                        this.edges.forEach(value => {
                            value.element.classList.add('hidden');
                        });
                        this.incoming.forEach(value => {
                            value.incoming.classList.add('hidden');
                            value.outgoing.classList.add('hidden');
                        });
                        this.outgoing.forEach(value => {
                            value.incoming.classList.add('hidden');
                            value.outgoing.classList.add('hidden');
                        });
                    }
                },
                unhide() {
                    if (this.hidden) {
                        this.hidden = false;
                        this.g.classList.remove('hidden');
                        this.edges.forEach(value => {
                            value.element.classList.remove('hidden');
                        });
                        this.incoming.forEach(value => {
                            value.incoming.classList.remove('hidden');
                            value.outgoing.classList.remove('hidden');
                        });
                        this.outgoing.forEach(value => {
                            value.incoming.classList.remove('hidden');
                            value.outgoing.classList.remove('hidden');
                        });
                    }
                },
                start() {
                    this.parent.clearMultiSelect();
                    this.moved = false;
                    this.dragging = true;
                    this.origin = {
                        x: this.transform.matrix.e,
                        y: this.transform.matrix.f
                    };
                    this.element.classList.add('ghost');
                    this.svg.classList.add('dragging');
                },
                drag(dx, dy, target) {
                    const sx = Math.round(Math.round(dx/40)*40);
                    const sy = Math.round(Math.round(dy/40)*40);
                    if (target !== this.hover) {
                        if (this.vertices.has(this.hover)) {
                            this.hover.classList.remove('active');
                        }
                        this.hover = target;
                        if (this.vertices.has(this.hover)) {
                            this.hover.classList.add('active');
                            this.transform.setTranslate(this.origin.x, this.origin.y);
                        } else {
                            this.transform.setTranslate(sx, sy);
                        }
                    } else if (!this.vertices.has(this.hover)) {
                        this.transform.setTranslate(sx, sy);
                    }
                    this.position = this.center.matrixTransform(this.transform.matrix);
                    this.incoming.forEach(node => node.update(this.position.x, this.position.y));
                    this.outgoing.forEach(node => node.update(this.position.x, this.position.y));
                    this.edges.forEach(node => node.update(this.position.x, this.position.y));
                },
                end(reset) {
                    const shifted = Math.abs(
                            this.transform.matrix.e - this.origin.x
                        ) >= 40 || Math.abs(
                            this.transform.matrix.f - this.origin.y
                        ) >= 40 || this.vertices.has(this.hover);
                    if (this.readonly || reset || !shifted) {
                        this.drag(this.origin.x, this.origin.y);
                    } else {
                        this.moved = true;
                    }

                    this.element.classList.remove('ghost');
                    this.svg.classList.remove('dragging');
                    if (this.vertices.has(this.hover)) {
                        this.hover.classList.remove('active');
                        const target = this.vertices.get(this.hover);
                        parent.$emit('link', {
                            id: this.id,
                            src: this.id,
                            dst: target.id,
                            reset: this.category === 'View' ? target.id : null,
                        });
                    } else if (!this.readonly && !reset && shifted) {
                        this.position = this.center.matrixTransform(this.transform.matrix);
                        parent.$emit('move', {
                            id: this.id,
                            x: this.position.x,
                            y: this.position.y,
                            group: this.grouping.get(this.hover) ?? null,
                        });
                    }
                    this.dragging = false;

                    this.hover = null;
                    this.origin = null;
                    return shifted && !reset;
                }
            };
            g.transform.baseVal.insertItemBefore(item.transform, 0);
            el.transform.baseVal.insertItemBefore(item.resize, 0);
            title.transform.baseVal.insertItemBefore(item.resizeTitle, 0);
            this.items.set(el, item);
            this.external.set(item.id, el);
            return item;
        },
        createLink(src, dst) {
            const dst_position = dst.center.matrixTransform(dst.transform.matrix);
            const src_position = src.center.matrixTransform(src.transform.matrix);

            const el = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            el.setAttribute('class', 'connector');
            el.setAttribute('filter', 'url(#connector)');
            el.setAttribute('fill-opacity', '0.4');
            el.setAttribute('fill', 'currentColor');
            this.updatePath(el, src_position.x, src_position.y, dst_position.x, dst_position.y);

            return {
                element: el,
                src: {
                    element: el,
                    state: 0,
                    p1: src_position,
                    p2: dst_position,
                    redraw: this.updatePath,
                    update(x, y) {
                        this.p1.x = x;
                        this.p1.y = y;
                        this.redraw(this.element, this.p1.x, this.p1.y, this.p2.x, this.p2.y);
                    },
                    activate() {
                        if (!this.element.classList.contains('active')) {
                            this.element.classList.add('active');
                        }
                    },
                    deactivate() {
                        if (this.element.classList.contains('active')) {
                            this.element.classList.remove('active');
                        }
                    }
                },
                dst: {
                    element: el,
                    state: 1,
                    p1: src_position,
                    p2: dst_position,
                    redraw: this.updatePath,
                    update(x, y) {
                        this.p2.x = x;
                        this.p2.y = y;
                        this.redraw(this.element, this.p1.x, this.p1.y, this.p2.x, this.p2.y);
                    },
                    activate() {
                        if (!this.element.classList.contains('active')) {
                            this.element.classList.add('active');
                        }
                    },
                    deactivate() {
                        if (this.element.classList.contains('active')) {
                            this.element.classList.remove('active');
                        }
                    }
                }
            };
        },
    },
};
</script>

<style lang="less">
@import "../../assets/less/theme.less";

svg.canvas {
    background-color: @global-muted-background;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
}
svg.canvas {
    height: 100dvh;
}
svg.canvas.invalid {
    border: thick solid @global-highlight-color;
}
.canvas .connector {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    color: @global-color;
}
.canvas .connector.active {
    color: @global-primary-background;
}
.canvas text {
    fill: @global-color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.canvas foreignObject {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.canvas .title {
    text-anchor: middle;
    font-size: 2rem;
    font-weight: bolder;
}
.canvas .group {
   fill: none;
   stroke: #8AA0D8;
   stroke-width: 1;
}
.canvas.dragging .group,
.canvas.multiselect .group {
   fill: #efefef;
}
.canvas .active .group,
.canvas.dragging .group:hover {
   fill: #BCCAEE;
}
.canvas .hidden {
    display: none;
}
.canvas .selection.outline {
   fill: none;
   stroke: #3a4a49;
   stroke-width: 2;
   stroke-dasharray: 10, 10;
}
.canvas .selection.active {
   fill: #BCCAEE;
   stroke: #8AA0D8;
   stroke-width: 1;
}
.canvas .draggable-sprite {
    fill: @global-highlight-color;
    color: @global-highlight-color;
    stroke: @global-color;
    stroke-width: 1;
}
.canvas .draggable-sprite:hover {
    color: @global-highlight-color-darker;
}
.canvas.dragging .draggable-sprite {
    fill: @global-highlight-color;
    color: @global-highlight-color;
    stroke: @global-color;
}
.canvas.DataType .draggable-sprite,
.canvas.Prototype .draggable-sprite,
.canvas.Command .draggable-sprite,
.canvas.Query .draggable-sprite,
.canvas.AggregateRoot .draggable-sprite,
.canvas.Subscription .draggable-sprite,
.canvas.Event .draggable-sprite,
.canvas.Process .draggable-sprite,
.canvas.UserInput .draggable-sprite,
.canvas.Subprocess .draggable-sprite,
.canvas.Interrupt .draggable-sprite,
.canvas.View .draggable-sprite,
.canvas.Context .draggable-sprite,
.canvas.Action .draggable-sprite,
.canvas.Component .draggable-sprite {
    fill: @global-color;
    color: @global-color;
    stroke: @global-inverse-color;
    pointer-events: none;
}
.canvas.Interrupt .draggable-sprite.UserInput,
.canvas.UserInput .draggable-sprite.Interrupt,
.canvas.Context .draggable-sprite.View,
.canvas.View .draggable-sprite.Context,
.canvas.Context .draggable-sprite.Action,
.canvas.Context .draggable-sprite.Context,
.canvas.Context .draggable-sprite.Component {
    fill: @global-highlight-color;
    color: @global-highlight-color;
    stroke: @global-color;
    pointer-events: unset;
}
.canvas .draggable-sprite.active {
    fill: @global-primary-background !important;
    color: @global-primary-background !important;
    stroke: @global-inverse-color !important;
    pointer-events: unset;
}
.canvas .draggable-sprite.active:hover {
    color: @global-primary-background-darker !important;
}
.canvas .draggable-sprite.disabled {
    fill: @global-color;
    color: @global-color;
    stroke: @global-inverse-color;
    pointer-events: none !important;
}
.canvas .draggable-sprite.warning {
  -webkit-filter: drop-shadow(0 0 3rem @global-warning-background);
  filter: drop-shadow(0 0 3rem @global-warning-background);
}
.canvas .draggable-sprite.invalid {
  -webkit-filter: drop-shadow(0 0 3rem @global-danger-background);
  filter: drop-shadow(0 0 3rem @global-danger-background);
}
.canvas .ghost {
    pointer-events: none !important;
}
.canvas .realm-label {
    font-size: 3rem;
    font-weight: bolder;
}
.canvas foreignObject {
    overflow: visible;
}
.canvas .annotation {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    stroke: #3a4a49;
    fill: #3a4a49;
    stroke-linecap: square;
    stroke-width: 1;
}
</style>
